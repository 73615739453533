/* eslint-disable no-underscore-dangle */
const TRADE_DIRECTIONS = ['export', 'import'];

const MAJOR_MILESTONES = {
  import: [
    'collection',
    'departure',
    'arrival',
    'customs_clearance_arrival',
    'delivery',
  ],
  export: [
    'collection',
    'customs_clearance_departure',
    'cut_off',
    'departure',
    'arrival',
    'delivery',
  ],
};

const MILESTONE_POSITION = {
  PAST: 'past',
  ACTIVE: 'active',
  PSEUDO_ACTIVE: 'pseudo-active',
  FUTURE: 'future',
  NA: 'not-applicable',
};

const MILESTONE_STATUS = {
  COMPLETED: 'completed',
  NOT_COMPLETE: 'not_complete',
  WARNING: 'warning',
  CRITICAL: 'critical',
  DELAY_WARNING: 'delay_warning',
  DELAY_CRITICAL: 'delay_critical',
  FREE_TIME: 'free-time',
};

const MILESTONE_DETAILED_REASON_KEYS = {
  OVERBOOKING: 'overbooking',
  VESSEL_CHANGE: 'vessel_change',
  OMIT: 'omit',
  CONGESTION: 'congestion',
  BAD_WEATHER: 'bad_weather',
  DOCS_IN_TRANSIT: 'docs_in_transit',
  INCOMPLETE_DOC: 'incomplete_doc',
  CUSTOMS_SCREENING: 'customs_screening',
  TRAFFIC_JAM: 'traffic_jam',
  SOCIAL_CONDITIONS: 'social_conditions',
  PRODUCTION_ISSUES: 'production_issues',
  AWAITING_SHIPPER_RESPONSE: 'awaiting_shipper_response',
  VESSEL_UNDECIDED: 'vessel_undecided',
  DELIVERY_ADJUSTMENT: 'delivery_adjustment',
  UNLOADING_SITE_CONDITIONS: 'unloading_site_conditions',
  AWAITING_INQUIRY_RESPONSE: 'awaiting_inquiry_response',
  AWAITING_CONFIRMATION: 'awaiting_confirmation',
  FULL_PARKING: 'full_parking',
  CONTAINER_DAMAGE: 'container_damage',
  TROUBLE_ON_BOARD: 'trouble_on_board',
  EQUIPMENT_ISSUE: 'equipment_issue',
  PORT_ACCIDENT: 'port_accident',
  SHIPPING_COMPANY_ISSUES: 'shipping_company_issues',
  PORT_STRIKE: 'port_strike',
  NATURAL_DISASTERS: 'natural_disasters',
  CARGO_DAMAGE: 'cargo_damage',
  PICKUP_ADJUSTMENT: 'pickup_adjustment',
  UNSPECIFIED: 'unspecified',
};

const DETAILED_REASONS_PER_MILESTONE = {
  preparation: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'production_issues',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
  ],
  collection: [
    'unspecified',
    'overbooking',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'traffic_jam',
    'social_conditions',
    'production_issues',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'natural_disasters',
    'pickup_adjustment',
  ],
  packing: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
  ],
  vanning: [
    'unspecified',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
  ],
  carrying_in: [
    'unspecified',
    'overbooking',
    'docs_in_transit',
    'incomplete_doc',
    'traffic_jam',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'natural_disasters',
  ],
  customs_clearance_departure: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'customs_screening',
    'social_conditions',
    'awaiting_shipper_response',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
  ],
  cut_off: ['unspecified', 'overbooking'],
  departure: [
    'unspecified',
    'overbooking',
    'vessel_change',
    'omit',
    'congestion',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'social_conditions',
    'vessel_undecided',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'shipping_company_issues',
    'port_strike',
    'natural_disasters',
  ],
  transship: [
    'unspecified',
    'overbooking',
    'vessel_change',
    'omit',
    'congestion',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'vessel_undecided',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'shipping_company_issues',
    'port_strike',
    'natural_disasters',
  ],
  arrival: [
    'unspecified',
    'vessel_change',
    'omit',
    'congestion',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'vessel_undecided',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'port_strike',
    'natural_disasters',
    'cargo_damage',
  ],
  customs_clearance_arrival: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'customs_screening',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
  ],
  devanning: [
    'unspecified',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'natural_disasters',
    'cargo_damage',
    'pickup_adjustment',
  ],
  unpacking: [
    'unspecified',
    'docs_in_transit',
    'incomplete_doc',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'natural_disasters',
    'cargo_damage',
    'pickup_adjustment',
  ],
  delivery: [
    'unspecified',
    'vessel_change',
    'bad_weather',
    'docs_in_transit',
    'incomplete_doc',
    'customs_screening',
    'traffic_jam',
    'delivery_adjustment',
    'unloading_site_conditions',
    'awaiting_confirmation',
    'awaiting_inquiry_response',
    'full_parking',
    'container_damage',
    'trouble_on_board',
    'equipment_issue',
    'port_accident',
    'natural_disasters',
    'cargo_damage',
    'pickup_adjustment',
  ],
};

const PAGINATION_PER_PAGE = 50;

const PRE_SHIPMENT_STATUS = 'pre_shipment';

// Local Storage key
// TODO: delete SHIPMENT_LIST_ACTIVE_FILTERS
// Keep this one for now, so we can delete it from users local storage.
const SHIPMENT_LIST_ACTIVE_FILTERS = 'shipment-list-active-filters';
const SHIPMENT_LIST_TRADE_DIRECTION = 'shipment-list-trade-direction';
const SHIPMENT_LIST_ACTIVE_VIEW = 'shipment-list-active-view';
const SHIPMENT_LIST_FILTERS = 'shipment-list-filters-v2';
const SHIPMENT_LIST_SORT = 'shipment-list-sort';

const TIME_CONSCIOUS_MILESTONES = [
  'collection', 'delivery', 'arrival', 'departure', 'transship',
];

const MILESTONE_TYPES_WITH_PORTS = [
  'arrival', 'departure', 'transship',
];

const TASK_STATUS = {
  COMPLETED: 'completed',
  NOT_COMPLETE: 'not_complete',
  WARNING: 'warning',
  CRITICAL: 'critical',
};

const MILESTONES_WITH_DRAYAGE_STATUS = [
  'collection', 'delivery',
];

const CARGO_TYPE = {
  FCL: 'FCL',
  LCL: 'LCL',
  AIR: 'AIR',
};

const REGEX_FOR_DXX_INCOTERMS = /^D/;

const FILE_GROUP_WIDTHS = {
  status: 4,
  category: 28,
  filename: 27,
  user: 18,
  datetime: 19,
  actions: 4,
  edit: (27 + 18 + 19 + 4),
};

const TASK_GROUP_WIDTHS = {
  status: 5,
  category: 60,
  datetime: 25,
  actions: 10,
};

const TASK_TYPES = {
  standard: 'standard',
  documentRequest: 'document_request',
};

const FILE_CATEGORIES = {
  miscDocuments: [
    'miscellaneous',
    'product_information',
    'other_custom_laws',
    'other_custom_clearance_materials',
  ],
  tradeDocuments: [
    'arrival_notice',
    'bill_of_lading',
    'customs_clearance_permit',
    'certificate_of_origin',
    'packing_list',
    'shipping_advice',
    'shipping_instructions',
    'insurance',
    'invoice',
  ],
};

const FILE_CATEGORIES_PER_DIRECTION = {
  import: {
    miscDocuments: [
      'miscellaneous',
      'product_information',
      'other_custom_laws',
      'other_custom_clearance_materials',
    ],
    tradeDocuments: [
      'arrival_notice',
      'bill_of_lading',
      'customs_clearance_permit',
      'certificate_of_origin',
      'packing_list',
      'shipping_advice',
      'insurance',
      'invoice',
    ],
  },
  export: {
    miscDocuments: [
      'miscellaneous',
      'product_information',
      'other_custom_clearance_materials',
    ],
    tradeDocuments: [
      'bill_of_lading',
      'customs_clearance_permit',
      'certificate_of_origin',
      'packing_list',
      'shipping_instructions',
      'insurance',
      'invoice',
    ],
  },
};

const FILE_SECTIONS = {
  icpDocuments: 'icpDocuments',
  forwarderDocuments: 'forwarderDocuments',
  miscDocuments: 'miscDocuments',
  tradeDocuments: 'tradeDocuments',
};

const NSS_FILTER_OPTIONS = {
  all: 'all',
  shippio: 'shippio',
  non_shippio: 'non_shippio',
};

const RESPONSIBILITY_TYPES = {
  shippio: 'shippio',
  non_shippio: 'non_shippio',
};

// 'import_request' is replaced by 'pre_shipment'(Feb 2021)
// Todo: Remove import_request when all 'import_request' are gone
const SHIPMENT_STATUS = {
  import_request: 'import_request',
  pre_shipment: 'pre_shipment',
  completed: 'completed',
};

const SHIPMENT_LIST_DELIVERY_HEADERS = [
  'product',
  'voyageInfo',
];

const SHIPMENT_LIST_DOWNLOAD_TYPES = {
  export_default: 'export_default',
  import_default: 'import_default',
  import_delivery: 'import_delivery_management',
};

const SHIPMENT_LIST_VIEWS = {
  default: 'default',
  delivery: 'delivery',
};

const SHIPMENT_LIST_VIEW_SIZES = {
  full: 'full',
  minimized: 'minimized',
};

const SHIPMENT_LIST_VIEW_WIDTHS = {
  [SHIPMENT_LIST_VIEWS.default]: {
    [SHIPMENT_LIST_VIEW_SIZES.full]: {
      forwarderIcon: 6,
      route: 20,
      cargoContents: 20,
      status: 14,
      milestones: 30,
      miscellaneous: 10,
    },
    [SHIPMENT_LIST_VIEW_SIZES.minimized]: {
      forwarderIcon: 15,
      route: 80,
      cargoContents: 0,
      status: 0,
      milestones: 0,
      miscellaneous: 0,
    },
  },
  [SHIPMENT_LIST_VIEWS.delivery]: {
    [SHIPMENT_LIST_VIEW_SIZES.full]: {
      forwarderIcon: 6,
      status: 14,
      product: 15,
      voyageInfo: 16,
      deliveryMilestone: 41,
      miscellaneous: 8,
    },
    [SHIPMENT_LIST_VIEW_SIZES.minimized]: {
      forwarderIcon: 15,
      status: 43,
      product: 42,
      voyageInfo: 0,
      deliveryMilestone: 0,
      miscellaneous: 0,
    },
  },
};

const MILESTONE_EVENT_ROW_WIDTHS = {
  [SHIPMENT_LIST_VIEW_SIZES.full]: {
    deliveryCargoPlace: 38,
    deliveryDateTimeBookingStatus: 62,
  },
  [SHIPMENT_LIST_VIEW_SIZES.minimized]: {
    deliveryCargoPlace: 0,
    deliveryDateTimeBookingStatus: 0,
  },
};

const VIEW_FILTER_OPTIONS = {
  import: [
    SHIPMENT_LIST_VIEWS.default,
    SHIPMENT_LIST_VIEWS.delivery,
  ],
  export: [
    SHIPMENT_LIST_VIEWS.default,
  ],
};

const DRAYAGE_RESERVATION_STATUS = [
  'not_reserved',
  'reserved',
  'needs_rescheduling',
];

const CUSTOM_ASSIGNED_TO_OPTIONS = {
  allAssignees: 'all_assignees',
  noAssignees: 'no_assignees',
};

const CUSTOM_ASSIGNED_TO_OPTION_TYPENAME = 'CustomShipmentListAssigneeToTypename';

const ASSIGNED_TO_URL_PARAMS_TYPENAME_MAP = {
  user: 'CustomerUser',
  group: 'UserGroup',
};

// Every filter must have a default value (even with null) for batch number counter
const DEFAULT_SHIPMENT_RICH_FILTERS = {
  shipmentStatus: 'all',
  nssFilter: 'all',
  shipperId: [],
  consigneeId: [],
  podId: [],
  polId: [],
  deliveryAddressId: null,
  assignedTo: [{
    id: CUSTOM_ASSIGNED_TO_OPTIONS.allAssignees,
  }],
  bookingStatus: null,
  customerTeamId: null,
  productIds: [],
};

const BOOKING_STATUS_FILTER_OPTIONS = {
  arranged: 'arranged',
  needs_reservation: 'needs_reservation',
};

const SHIPMENT_FOCUS_OPTIONS = {
  chat: 'chat',
};

const CONTAINER_TYPES_MAP = {
  _20ft: '_20ft',
  _40ft: '_40ft',
  _20ft_flat: '_20ft_flat',
  _40ft_flat: '_40ft_flat',
  _20ft_open_top: '_20ft_open_top',
  _40ft_open_top: '_40ft_open_top',
  _20ft_reefer: '_20ft_reefer',
  _40ft_reefer: '_40ft_reefer',
  _40ft_hc: '_40ft_hc',
};

const CONTAINER_TYPES = [
  CONTAINER_TYPES_MAP._20ft,
  CONTAINER_TYPES_MAP._40ft,
  CONTAINER_TYPES_MAP._20ft_flat,
  CONTAINER_TYPES_MAP._40ft_flat,
  CONTAINER_TYPES_MAP._20ft_open_top,
  CONTAINER_TYPES_MAP._40ft_open_top,
  CONTAINER_TYPES_MAP._20ft_reefer,
  CONTAINER_TYPES_MAP._40ft_reefer,
  CONTAINER_TYPES_MAP._40ft_hc,
];

const ACCURACY_LEVELS = {
  normal: 'normal',
  temporary: 'temporary',
  low: 'low',
};

const ACCURACY_POSITION_TYPES = {
  nextToEventTitle: 'nextToEventTitle',
  nextToMilestoneDateTime: 'nextToMilestoneDateTime',
};

const MILESTONE_TYPES = {
  preparation: 'preparation',
  collection: 'collection',
  packing: 'packing',
  vanning: 'vanning',
  carrying_in: 'carrying_in',
  customs_clearance_departure: 'customs_clearance_departure',
  cut_off: 'cut_off',
  departure: 'departure',
  transship: 'transship',
  arrival: 'arrival',
  customs_clearance_arrival: 'customs_clearance_arrival',
  devanning: 'devanning',
  unpacking: 'unpacking',
  delivery: 'delivery',
};

const CAR_CONTACT_INFO_LEAVE_DELAY_AFTER_COPIED = 1000; // 1 second
const CAR_CONTACT_INFO_REOPEN_DELAY_AFTER_COPIED = 3000; // 3 second

const ATTACHMENT_USER_VISIBILITIES = {
  staff_cpu: 'staff_cpu',
  staff_cu_cpu: 'staff_cu_cpu',
  staff_cu: 'staff_cu',
  CustomerUser_ForwarderUser: 'customerUser_forwarderUser',
};

const INSURANCE_HANDLING_OPTIONS = [
  'self_coverage',
  'shippio_covers',
  'consultation_required',
  'no_insurance',
];

const CUSTOMS_HANDLING_OPTIONS = [
  'real_time',
  'deferment',
  'consultation_required',
  'prepaid',
  'pay_on_behalf',
];

const NSS_AUTO_TRACKING_MILESTONES = {
  [MILESTONE_TYPES.departure]: MILESTONE_TYPES.departure,
  [MILESTONE_TYPES.arrival]: MILESTONE_TYPES.arrival,
  [MILESTONE_TYPES.transship]: MILESTONE_TYPES.transship,
};

const TRANSPORTATION_MODES = {
  ocean: 'ocean',
  air: 'air',
};

const PLACE_TYPES = {
  [MILESTONE_TYPES.collection]: MILESTONE_TYPES.collection,
  [MILESTONE_TYPES.delivery]: MILESTONE_TYPES.delivery,
};

const DRAYAGE_CHASSIS_TYPES = {
  _20ft_2axles: '_20ft_2axles',
  _20ft_3axles: '_20ft_3axles',
  _40ft_2axles: '_40ft_2axles',
  _40ft_3axles: '_40ft_3axles',
};

const PLACES_MILESTONES = [MILESTONE_TYPES.collection, MILESTONE_TYPES.delivery];

const TRANSIT_MILESTONES = [
  MILESTONE_TYPES.departure,
  MILESTONE_TYPES.transship,
  MILESTONE_TYPES.arrival,
];
const MUST_EXIST_MILESTONES = [
  MILESTONE_TYPES.preparation,
  MILESTONE_TYPES.departure,
  MILESTONE_TYPES.arrival,
];

const NEED_TWO_EVENT_MILESTONES_TYPES = [
  MILESTONE_TYPES.packing,
  MILESTONE_TYPES.unpacking,
  MILESTONE_TYPES.transship,
];

// HACK: In the case of draftMilestone,
// eventName cannot be obtained because it is not stored in back-end.
// Refer to back-end
// https://github.com/shippio/back-end/blob/4d554a868753d0cf645e0e4a2baa93e21b5a1e83/monolith/app/models/milestone_event.rb#L159
const EVENT_NAMES_FOR_MILESTONE_TYPE = Object.freeze({
  [MILESTONE_TYPES.preparation]: ['Cargo Ready 予定日'],
  [MILESTONE_TYPES.collection]: ['バンニング予定日'],
  [MILESTONE_TYPES.packing]: ['梱包業者への到着予定日', '梱包完了予定日'],
  [MILESTONE_TYPES.vanning]: ['バンニング予定日'],
  [MILESTONE_TYPES.carrying_in]: ['搬入予定日'],
  [MILESTONE_TYPES.customs_clearance_departure]: ['通関予定日'],
  [MILESTONE_TYPES.cut_off]: [{ LCL: 'CFS CUT', FCL: 'CY CUT' }],
  [MILESTONE_TYPES.departure]: ['ETD'],
  [MILESTONE_TYPES.transship]: ['ETA', 'ETD'],
  [MILESTONE_TYPES.arrival]: ['ETA'],
  [MILESTONE_TYPES.customs_clearance_arrival]: ['通関予定日'],
  [MILESTONE_TYPES.devanning]: ['デバンニング予定日'],
  [MILESTONE_TYPES.unpacking]: ['開梱業者への到着予定日', '開梱完了予定日'],
  [MILESTONE_TYPES.delivery]: ['納品予定日'],
});

const AUTO_TRACKING_STATUSES = Object.freeze({
  CARGO: 'cargo',
  VESSEL: 'vessel',
  DISABLED: 'disabled',
  NOT_SUPPORTED: 'not_supported',
  PREPARING: 'initial_cleansing_in_progress',
});

const AUTO_TRACKING_REQUIRED_INFORMATION_ENUM = Object.freeze({
  MBL_NUMBER: 'mbl',
  BOOKING_NUMBER: 'bkg',
  CONTAINER_NUMBER: 'ctn',
  VESSEL_NAME: 'vessel_name',
  VOYAGE_NUMBER: 'voyage_number',
  CARRIER: 'carrier',
  POL: 'pol',
  POD: 'pod',
});

const REQUIRED_FOR_CARGO_TRACKING = Object.freeze([
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.MBL_NUMBER,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.BOOKING_NUMBER,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.CONTAINER_NUMBER,
]);

const REQUIRED_FOR_VESSEL_TRACKING = Object.freeze([
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.VESSEL_NAME,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.VOYAGE_NUMBER,
]);

const MANDATORY_FOR_AUTO_TRACKING = Object.freeze([
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.CARRIER,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.POL,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM.POD,
]);

export {
  CARGO_TYPE,
  FILE_CATEGORIES,
  FILE_CATEGORIES_PER_DIRECTION,
  FILE_GROUP_WIDTHS,
  MAJOR_MILESTONES,
  MILESTONE_DETAILED_REASON_KEYS,
  MILESTONE_POSITION,
  MILESTONE_STATUS,
  MILESTONE_TYPES_WITH_PORTS,
  MILESTONES_WITH_DRAYAGE_STATUS,
  PAGINATION_PER_PAGE,
  PRE_SHIPMENT_STATUS,
  REGEX_FOR_DXX_INCOTERMS,
  SHIPMENT_LIST_ACTIVE_FILTERS,
  SHIPMENT_LIST_TRADE_DIRECTION,
  SHIPMENT_LIST_ACTIVE_VIEW,
  SHIPMENT_LIST_FILTERS,
  SHIPMENT_LIST_SORT,
  TASK_GROUP_WIDTHS,
  TASK_STATUS,
  TASK_TYPES,
  TIME_CONSCIOUS_MILESTONES,
  TRADE_DIRECTIONS,
  FILE_SECTIONS,
  RESPONSIBILITY_TYPES,
  SHIPMENT_STATUS,
  SHIPMENT_LIST_DELIVERY_HEADERS,
  SHIPMENT_LIST_DOWNLOAD_TYPES,
  SHIPMENT_LIST_VIEWS,
  SHIPMENT_LIST_VIEW_SIZES,
  SHIPMENT_LIST_VIEW_WIDTHS,
  VIEW_FILTER_OPTIONS,
  DRAYAGE_RESERVATION_STATUS,
  MILESTONE_EVENT_ROW_WIDTHS,
  DEFAULT_SHIPMENT_RICH_FILTERS,
  CUSTOM_ASSIGNED_TO_OPTIONS,
  CUSTOM_ASSIGNED_TO_OPTION_TYPENAME,
  ASSIGNED_TO_URL_PARAMS_TYPENAME_MAP,
  BOOKING_STATUS_FILTER_OPTIONS,
  SHIPMENT_FOCUS_OPTIONS,
  CONTAINER_TYPES,
  CONTAINER_TYPES_MAP,
  ACCURACY_LEVELS,
  ACCURACY_POSITION_TYPES,
  MILESTONE_TYPES,
  CAR_CONTACT_INFO_LEAVE_DELAY_AFTER_COPIED,
  CAR_CONTACT_INFO_REOPEN_DELAY_AFTER_COPIED,
  ATTACHMENT_USER_VISIBILITIES,
  INSURANCE_HANDLING_OPTIONS,
  CUSTOMS_HANDLING_OPTIONS,
  NSS_AUTO_TRACKING_MILESTONES,
  TRANSPORTATION_MODES,
  PLACE_TYPES,
  DRAYAGE_CHASSIS_TYPES,
  NSS_FILTER_OPTIONS,
  DETAILED_REASONS_PER_MILESTONE,
  PLACES_MILESTONES,
  TRANSIT_MILESTONES,
  MUST_EXIST_MILESTONES,
  NEED_TWO_EVENT_MILESTONES_TYPES,
  EVENT_NAMES_FOR_MILESTONE_TYPE,
  AUTO_TRACKING_STATUSES,
  AUTO_TRACKING_REQUIRED_INFORMATION_ENUM,
  REQUIRED_FOR_CARGO_TRACKING,
  REQUIRED_FOR_VESSEL_TRACKING,
  MANDATORY_FOR_AUTO_TRACKING,
};
