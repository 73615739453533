import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import '../shared/index.scss';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Auth0ProviderWithHistory from '@/shared/components/Auth0/Auth0ProviderWithHistory';

import ApolloWrapper from '../graphql/ApolloWrapper';
import './i18n';
import * as serviceWorker from '../serviceWorker';
import middlewareEnhancer from '../shared/redux/middlewareEnhancer';
import generateStore from '../shared/redux/store';
import App from './App';
import rootReducer from './redux/rootReducer';

const store = generateStore(rootReducer, middlewareEnhancer);

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <Provider store={store}>
          <Router>
            <Auth0ProviderWithHistory>
              <ApolloWrapper>
                <App />
              </ApolloWrapper>
            </Auth0ProviderWithHistory>
          </Router>
        </Provider>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

renderApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
